<template>
  <div class="v1paymentmore">
    <van-nav-bar
      title="确认订单"
      left-arrow
      @click-left="onClickLeft"
      @click-right="goHome"
      right-text="返回首页"
    />
    <!-- 未登录 -->
    <div class="logout" v-if="!islogin">
      <div class="login_tishi">您还未登录，请点击登陆按钮进行登录后再购买</div>
      <van-button @click="gologin" type="primary">点击登陆</van-button>
    </div>
    <!-- 收货地址信息 -->
    <div class="postage_address" v-if="goodstype == 2 || goodstype == 3 ? false : true">
      <div class="address_list" v-if="islogin && address.length > 0">
        <van-cell
          :border="false"
          :data-login="islogin"
          center
          v-for="(item, index) in address"
          :key="index"
        >
          <template #icon>
            <van-icon @click="shibieAddress(index)" class="address_icon" name="location-o"></van-icon>
          </template>
          <template #right-icon>
            <!-- <van-icon @click="addAddress" class="check_address" name="arrow"></van-icon> -->
            <van-image
              :src="item.goods && item.goods.info && item.goods.info[0]['goods_info'][0].goodsthumb"
              style="width: 40px; height: 40px; display: block;"
              lazy-load
              @click="chooseGoods(index)"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </template>
          <template #title>
            <div @click="shibieAddress(index)" class="van-multi-ellipsis--l2">
              <div class="name">{{ item.name }} {{ item.mobile }}</div>
              <div class="area">{{ item.province }} {{ item.city }} {{ item.area }} {{ item.street }}</div>
            </div>
          </template>
        </van-cell>
      </div>
      
      <!-- 没有收货地址的时候 -->
      <van-cell :border="false" :data-login="islogin" center v-if="!islogin || address.length <= 0">
        <template #icon>
          <van-icon @click="addMoreAddress" class="address_icon add" name="add-square"></van-icon>
        </template>
        <template #title>
          <div @click="addMoreAddress" class="van-multi-ellipsis--l2">添加多个收货人</div>
        </template>
      </van-cell>
    </div>
    <div class="postage_address" v-if="(goodstype == 2 || goodstype == 3) && islogin && address.length <= 0 ? false : true">
      <div style="padding: 10px 16px; text-align: right;">
        <van-uploader accept=".xlsx" :after-read="uploadExcel">
          <van-button size="small" style="margin-right: 20px;">表格导入地址</van-button>
        </van-uploader>
        <!-- <van-button size="small" style="margin-right: 20px;" @click="getexcel">表格导入地址</van-button> -->
        <!-- <van-button size="small" @click="downloadExcel">下载导入模板</van-button> -->
        <van-button size="small" @click="showExcel">查看导入模板</van-button>
      </div>
      
    </div>
    <!-- 虚拟商品显示手机号 -->
    <div class="postage_address" v-if="goodstype == 2 || goodstype == 3 ? true : false">
      <van-field v-model="mobile" type="tel" ref="mobileInput" :error="mobileError" label="手机号" placeholder="请输入手机号" />
    </div>
    <div class="order_total">
      <van-cell-group :border="false">
        <van-cell :border="false" title="商品件数">
          <template #default>{{ order.totalNum }}件</template>
        </van-cell>
        <van-cell :border="false" title="商品金额">
          <template #default>&yen;{{ order.goodsPrice }}</template>
        </van-cell>
        <van-cell v-if="goodstype == 2 || goodstype == 3 ? false : true" :border="false" title="运费">
          <template #default>&yen;{{ order.postPrice }}</template>
        </van-cell>
        <van-cell title="优惠">
          <template #default>
            <span class="red">&minus;&yen;{{ order.discountprice }}</span>
          </template>
        </van-cell>
        <van-cell title="满减" v-if="order.deductPrice && order.deductPrice > 0">
          <template #default>
            <span class="red">&minus;&yen;{{ order.deductPrice }}</span>
          </template>
        </van-cell>
        <van-cell>
          <template #default>
            合计：
            <span class="red">&yen;{{ order.totalPrice }}</span>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <van-submit-bar
      :price="order.totalPrice * 100"
      button-text="提交订单"
      :disabled="islogin && !noxiadan ? false : true"
      :loading="!loadingbtn"
      :tip="noxiadanText"
      @submit="onSubmit"
    >
    </van-submit-bar>

    <transition name="van-slide-right"> 
      <div class="demo_cont" v-show="showshibieAddress">
        <van-form @submit="onSaveAddress" class="demo-animate-block">
          <van-icon name="close" size="26" color="#fff" @click="showshibieAddress = false" style="position: absolute; top: -30px;right: 0px;"></van-icon>
          <van-field
            name="conName"
            v-model="Addaddress.conName"
            label="姓名"
            placeholder="收货人姓名"
            :rules="[{ required: true, message: '请输入收货人姓名' }]"
          />
          <van-field
            name="conMobile"
            v-model="Addaddress.conMobile"
            label="电话"
            type="tel"
            placeholder="收货人手机号"
            :rules="[{ validator, message: '请输入收货人手机号' }]"
          />
          <van-field
            readonly
            clickable
            name="area"
            v-model="Addaddress.area"
            label="地区"
            placeholder="点击选择省市区"
            @click="showArea = true"
            :rules="[{ valiarea, message: '请选择省市区' }]"
          />
          <van-field
            name="street"
            v-model="Addaddress.street"
            label="详细地址"
            placeholder="街道门牌、楼层房间号等信息"
            :rules="[{ required: true, message: '请输入详细地址' }]"
          />
          <div class="address_shibie">
            <van-field
              ref="addressStr"
              v-model="addressStr"
              rows="3"
              type="textarea"
              placeholder="请输入地址信息 可自动识别"
            />
            <van-button size="small" plain type="primary" native-type="button" hairline @click="parseAddress">识别</van-button>
          </div>
          <van-popup v-model="showArea" position="bottom">
            <van-area
              :value="Addaddress.areaCode"
              :area-list="areaList"
              @confirm="onConfirm"
              @cancel="showArea = false"
            />
          </van-popup>
          <div style="margin: 16px;">
            <van-button round block type="info" :loading="saveAddBtn" :native-type="!saveAddBtn ? 'submit' : 'button'">
              保存
            </van-button>
          </div>
        </van-form>
      </div>
    </transition>
    <van-dialog
      v-model="showChoseGoods"
      show-cancel-button
      width="90%"
      @confirm="orderinfoConfirm"
      @cancel="orderinfoCancel"
    >
      <div class="search" :style="'max-height: ' + (screeHeight*0.8) + 'px;'">
        <van-search
          v-model="keywords"
          show-action
          shape="round"
          placeholder="请输入搜索关键词"
          @input="keywordsChange"
          @search="kwdonSearch"
        >
          <template #action>
            <div @click="kwdonSearch">搜索</div>
          </template>
        </van-search>
        <div class="goodslist_main" v-if="showgoodslist" :style="'height: '+(screeHeight*0.7-55)+'px;'">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="kwdonSearch"
          >
            <van-cell
              v-for="(item, index) in goodsList"
              :key="index"
              center
              is-link
              @click="checkgoods(item.id)"
            >
              <template #icon>
                <van-image
                  :src="item.thumb"
                  style="width: 60px; height: 60px; display: block;margin-right: 10px;"
                  lazy-load
                  fit="cover"
                >
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </template>
              <template #title>
                <div>{{item.title}}</div>
              </template>
            </van-cell>
          </van-list>
          <!-- 产品sku -->
          <van-sku
            v-model="showBase"
            :sku="skuData.sku"
            :goods="skuData.goods"
            :goods-id="skuData.goodsid"
            :hide-stock="skuData.sku.hidestock"
            :quota="skuData.quota"
            :quota-used="skuData.quotaused"
            :initial-sku="skuData.initialSku"
            :price-tag="yushouTime"
            reset-stepper-on-hide
            reset-selected-sku-on-hide
            disable-stepper-input
            :close-on-click-overlay="closeOnClickOverlay"
            :custom-sku-validator="customSkuValidator"
            @buy-clicked="onBuyClicked"
            @sku-selected="skucheck"
            lazy-load
            ref="vanskudata"
          >
            <template #sku-header-image-extra>
              <span class="shuiyin">{{ strId }}</span>
            </template>
            <template #sku-actions="props">
              <div class="van-sku-actions">
                <!-- 直接触发 sku 内部事件，通过内部事件执行 onBuyClicked 回调 -->
                <van-button
                  square
                  size="large"
                  type="danger"
                  @click="props.skuEventBus.$emit('sku:buy')"
                >确定</van-button>
              </div>
            </template>
          </van-sku>
        </div>
        <div class="goods_main" v-if="showOrderinfo">
          <div class="goods_list" v-if="orderinfo && orderinfo.length > 0">
            <div class="goods_list_detail" v-for="(val, key) in orderinfo" :key="key">
              <van-card
                v-for="(item, index) in val.goods_info"
                :key="index"
                :price="item.marketprice*100 | currencyFormat"
                :title="strId + item.goodstitle"
                :tag="item.meihuo == 1 ? '暂无库存' : ''"
              >
                <template #desc>
                  {{item.optionname ? item.optionname : ''}}
                  <van-tag color="#9168ed" text-color="#fff" v-if="item.yushouTime">{{item.yushouTime}}</van-tag>
                </template>
                <template #thumb>
                  <van-image :src="item.goodsthumb" lazy-load>
                    <template v-slot:loading>
                      <van-loading type="spinner" size="20" />
                    </template>
                  </van-image>
                </template>
                <template #num>
                  <van-stepper
                    v-model="item.num"
                    :name="index"
                    min="1"
                    :max="item.stock"
                    @change="numOnChange"
                  />
                </template>
              </van-card>
              <div class="goods_set">
                <van-field
                  :border="false"
                  input-align="right"
                  v-model="val.message"
                  @input="messageInput($event, key)"
                  label="买家留言"
                  placeholder="建议留言前先与商家沟通确认"
                />
                <van-cell v-if="goodstype == 2 || goodstype == 3 ? false : true" :border="false" title="运费">
                  <template #default>&yen;{{ val.totalYunFei.toFixed(2) }}</template>
                </van-cell>
                <van-cell v-if="(val.deductPrice && parseFloat(val.deductPrice) > 0)" :border="false" title="满减">
                  <template #default><span class="red">&minus;&yen;{{ val.deductPrice }}</span></template>
                </van-cell>
                <div class="kongjia_tupian" v-if="val.is_kongjia == 1">
                  <van-field center label="产品控价,上传老顾客截图,格式可咨询客服">
                    <template #input>
                      <van-uploader v-model="uploadpic" :name="key" max-count="1" :after-read="afterRead" :before-read="beforeRead" :before-delete="beforeDelete" />
                    </template>
                    <template #right-icon>
                      <van-icon name="arrow" />
                    </template>
                  </van-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import areaList from "@/components/area.js";
import SmartAddress from 'react-smart-address';
import { ImagePreview } from 'vant';
import skuData from "@/components/data.js";
export default {
  name: "V1PaymentMore",
  filters: {
    currencyFormat: function(num) {
      if (num === 0) {
        return "0.00";
      } else {
        num = num + "";
        if (num.length == 1) {
          num = "00" + num;
        }
        if (num.length == 2) {
          num = "0" + num;
        }
        let start = 0;
        start = num.length - 2;
        return num.slice(0, start) + "." + num.slice(start);
      }
    },
    dateFormat: function(value) {
      let date = null;
      let y = null;
      date = new Date(value);
      y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      /*
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      */
      return y + "/" + MM + "/" + d; // + ' ' + h + ':' + m + ':' + s;
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      user: {},
      goods: [],
      req: null, // 数据接收值
      islogin: localStorage.getItem("DK_ISLOGIN") ? true : false,
      address: [], // 收货地址 { name: "", mobile: "", province: "", city: "", county: "", area: "", street: "", areaCode: "", id: "" }
      openid: "",
      mobile: "", // 手机号
      addressStr: "",
      uploadpic: [],
      kongjiapic: "", // 控价图片链接地址
      iskongjia: false, // 默认隐藏控价
      shareid: 0,
      merchid: 0,
      noxiadan: false, // 默认能下单
      noxiadanText: "",
      orderid: "", // 订单id
      ordersn: "", // 订单编号
      loadingbtn: false,
      showshibieAddress: false, // 地址识别弹框
      areaList: areaList,
      showArea: false, // 默认不显示收货地址
      Addaddress: {},
      agentInfo: {}, // 上级信息
      agentCode: "", // 上级邀请码
      strId: "",
      messageArr: [], // 留言信息
      newopenid: null, // 合单支付时的openID
      iswx: false, 
      errorText: "",
      goodstype: 0, // 商品类型：1实物， 2虚拟产品
      mobileError: false, // 虚拟产品输入手机号有误时内容标红
      deductPrice: 0, // 总满减额
      saveAddBtn: false, // 保存地址
      order: {}, // 预览订单信息
      showChoseGoods: false, // 选择商品弹框
      keywords: "", // 搜索关键词
      orderinfo: [], // 商品信息
      showOrderinfo: false, // 默认显示订单信息
      showgoodslist: false, // 默认产品搜索结果隐藏
      loading: false,
      finished: false,
      nodata: false,
      offset: 0,
      size: 10,
      goodsList: [],
      skuData: skuData,
      info: {}, // 商品详情
      closeOnClickOverlay: true,
      yushouTime: "",
      skuTitle: "", // sku标题
      showBase: false
    };
  },
  mounted() {
    if (localStorage.getItem("DK_ADDRESS")) {
      this.address = JSON.parse(localStorage.getItem("DK_ADDRESS"));
    }
  },
  activated() {
    this.islogin = localStorage.getItem("DK_ISLOGIN") ? true : false;
    if (localStorage.getItem("DK_DATA")) {
      this.req = localStorage.getItem("DK_DATA");
      this.req = this.$qs.parse(this.req);
    }

    if (this.islogin && localStorage.getItem("DK_ADDRESS")) {

      let address = [];

      address = JSON.parse(localStorage.getItem("DK_ADDRESS"));
      console.log(address, "activated address");
      if (address.length > 0) {
        address.forEach((item, index) => {
          if (!this.address[index] || !this.address[index].id || parseInt(this.address[index].id) != parseInt(item.id)) {
            if (!this.address[index]) {
              this.address[index] = {}
            }
            this.address[index].id = item.id;
            this.address[index].name = item.name;
            this.address[index].mobile = item.mobile;
            this.address[index].province = item.province;
            this.address[index].city = item.city;
            this.address[index].area = item.area;
            this.address[index].areaCode = item.areaCode;
            this.address[index].street = item.street;
          }else{
            if (this.address[index].goods.kongjiapic && this.address[index].goods.kongjiapic.length > 10) {
              this.uploadpic[index] = {url: this.address[index].goods.kongjiapic, isImage: true};
            }
          }
        })
      }
      
      if (this.req) {
        console.log(this.req, "activated this.req");
        if (this.address && this.address.length > 0) {
          this.address.forEach((item, index) => {
            console.log(item, "activated item");
            if(!item.goods || parseInt(item.goods.goodsid) <= 0) {
              if (!item.goods) {
                this.address[index].goods = {};
              }
              this.address[index].goods.goodsid = this.req[0].goodsid;
              this.address[index].goods.optionid = this.req[0].optionid;
              this.address[index].goods.goodsnum = this.req[0].num;
              this.address[index].goods.merchid = this.req[0].merchid;
              this.address[index].goods.kongjiapic = this.req[0].kongjiapic ? this.req[0].kongjiapic : '';
              this.address[index].goods.message = this.req[0].message ? this.req[0].message : '';
            }
          })
        }
      }
      console.log(this.address, "activated this.address");
    }else{
      this.address = [];
    }
    if (window.isWeixin()) {
      this.iswx = true;  // 在微信中打开
    }else{
      this.iswx = false; // 不在微信中打开
    }

    this.openid = localStorage.getItem("DK_OPENID");
    
    this.newopenid = localStorage.getItem("DK_NEW_OPENID") ? localStorage.getItem("DK_NEW_OPENID") : null;
    
    if (!this.newopenid && this.iswx) {
      this.code = this.$route.query.code ? this.$route.query.code : null;
      if (this.code) {
        this.getwxlog(this.code);
      }else {
        this.getopenid();
      }
    }
    
    this.loadingbtn = false;
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "确认订单");

    if (!this.islogin && this.shareid) {
      this.getAgentInfo();
      setTimeout(() => {
        this.btnDisabled = true;
      }, 2);
    }
    this.strId = localStorage.getItem("DK_STRID");
    this.init();
  },
  deactivated() {
    localStorage.removeItem("DK_ADDRESS");
    this.address = []
  },
  methods: {
    validator(val) {
      return /1\d{10}$/.test(val); // 校验函数返回 true 表示校验通过，false 表示不通过
    },
    gologin() {
      this.$router.push({ name: "Login", query: { shareid: this.shareid, merchid: this.merchid } });
    },
    valiarea(val) {
      let area = false;
      let areaArr = [];
      if (val) {
        if (val.indexOf("/") !== -1) {
          areaArr = val.split("/");
          if (areaArr.length >= 3) {
            area = true;
          }
        }
      }
      return area;
    },
    init() {
      this.totalPrice = 0; // 订单金额
      this.goodsPrice = 0; // 商品金额
      this.postPrice = 0; // 运费金额

      // 获取产品信息
      if (this.req) {
        this.getgoodsinfo();
      }
    },

    /**
     * 微信code值
     */
    getopenid() {
      let _that = null;
      _that = this;
      /* 以下下是获取openID 以及判断用户是否在微信中打开页面 */
      let openid = null;
      openid = localStorage.getItem("DK_NEW_OPENID") ? localStorage.getItem("DK_NEW_OPENID") : false;

      /* 在微信中打开页面 */
      if (!openid && this.iswx) { 
        const wxAuthUrl = 
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx65fad56bebb47a1e&redirect_uri=" + encodeURIComponent(window.location.href) + "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";

          // 传到后台得到openid, 其中access_token 2小时过期
          if (_that.code == null || _that.code === "") {
            window.location.href = wxAuthUrl; // [自刷新当前页]
          }
      } else {
        // localStorage存在openid || 本页面不在公众号中打开
        console.log("localStorage存在openid || 本页面不在公众号中打开");
      }
    },

    /**
     * 获取微信openID
     */
    getwxlog(code) {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_new_oauth2",
          _that.$qs.stringify({
            code: code
          })
        )
        .then(function(response) {
          if (response.data) {
            localStorage.setItem("DK_NEW_OPENID", response.data); // 获得用户的openid
            /* 保存openID */
            // _that.saveOpenid();
            _that.goPay();
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    // 检查手机号绑定的会员id是否绑定openid
    checkMobile() {
      let _that = null;
      _that = this;
      if (_that.mobile) {
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/check_mobile",
            _that.$qs.stringify({
              mobile: _that.mobile
            })
          )
          .then(response => {
            console.log(response);
            if (response.data.code == 100000) {
              _that.btnDisabled = false;
            }else {
              _that.$notify({
                type: "warning",
                message: response.data.msg ? response.data.msg : "手机号已使用，请更换手机号",
                duration: 1500
              })
              _that.btnDisabled = true;
            }
          })
          .catch(error => {
            console.log(error);
          })
      }
        
    },
    // 根据openid 获取用户信息
    getopenidInfo(openid) {
      let _that = null;
      _that = this;

      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/userInfoByOpenid",
          _that.$qs.stringify({
            openid: openid
          })
        )
        .then(res => {
          if (res.data.code == 100000) {
            if (res.data.user.mobile) {
              localStorage.setItem("DK_UID",res.data.data);
              localStorage.setItem("DK_ISLOGIN",true);
              localStorage.setItem("DK_OPENID",openid);
              localStorage.setItem("DK_MEMBERID", res.data.user.id)
              localStorage.setItem("DK_STRID",res.data.user.strId);
              _that.strId = res.data.user.strId;
              _that.islogin = true;
              _that.openidBindUid(); // 绑定用户id
            }
          }
        })
    },
    // 获取产品信息
    getgoodsinfo() {
      let _that = null;
      _that = this;
      let DKUID = "";
      DKUID = localStorage.getItem("DK_UID");
      if (_that.req) {
        if (typeof _that.req === String) {
          _that.req = _that.$qs.parse(_that.req);
        }
      }

      const order = [];
      const goods = [];
      console.log(_that.address, "_that.address");
      if (_that.address && _that.address.length > 0) {
        _that.address.forEach(item => {
          const goods = [];
          if (item.goods && parseInt(item.goods.goodsid) > 0) {
            console.log(item.goods);
            goods.push({
              goodsid: item.goods.goodsid,
              optionid: item.goods.optionid,
              num: item.goods.goodsnum,
            });
          }else {
            goods.push({
              goodsid: _that.req[0].goodsid,
              optionid: _that.req[0].optionid,
              num: _that.req[0].num,
            });
          }
          order.push({
            goods: goods,
            "merchid_fake": _that.merchid,
            "address_ids": item.id
          });
        })
      }else {
        goods.push({
          goodsid: _that.req[0].goodsid,
          optionid: _that.req[0].optionid,
          num: _that.req[0].num,
        });
        order.push({
          goods: goods,
          "merchid_fake": _that.merchid,
          "address_ids": 0
        });
      }
      
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers.common["Authorization"] = DKUID;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/orderv2/confirm",
          _that.$qs.stringify({
            order: order
          })
        )
        .then(response => {
          _that.loadingbtn = true;
          if (response && response.data && response.data.code == 100000) {
            _that.order = response.data.data;
            _that.goodstype = _that.order.goods_info[0].goods[0].goods_info[0].goods_type && parseInt(_that.order.goods_info[0].goods[0].goods_info[0].goods_type) > 0 ? parseInt(_that.order.goods_info[0].goods[0].goods_info[0].goods_type) : 1;

            _that.order.totalNum = parseInt(_that.order["total_num"]);
            _that.order.goodsPrice = parseFloat(_that.order.marketPrice).toFixed(2);
            _that.order.totalPrice = parseFloat(_that.order.payPrice).toFixed(2);
            _that.order.postPrice = parseFloat(_that.order.totalYunFei).toFixed(2);
            _that.order.discountprice = parseFloat(_that.order.discountprice).toFixed(2);
            _that.order.deductPrice = parseFloat(_that.order.totalDeduct).toFixed(2);

            _that.address.forEach((item, index) => {
              _that.address[index].goods.info = _that.order.goods_info[index].goods;
            })

            _that.noxiadan = false;
            _that.noxiadanText = "";
          }else {
            
            _that.noxiadan = true;
            _that.noxiadanText = response.data.msg ? response.data.msg : "";
          }
          if (response && response.data && response.data.userinfo) {
            _that.user = response.data.userinfo;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // 回首页
    goHome() {
      let _that = null;
      _that = this;
      _that.$router.replace({name: "Home", query: { shareid: _that.shareid, merchid: _that.merchid, _t: 3 } });
    },
    // 返回上一页
    onClickLeft() {
      localStorage.removeItem("DK_ADDRESS");
      localStorage.removeItem("DK_DATA");
      localStorage.removeItem("money");
      localStorage.removeItem("ordersn");
      localStorage.removeItem("orderid");
      this.$router.go(-1);
    },
    // 添加收货地址
    addAddress() {
      console.log(this.islogin);
      if (this.islogin) {
        this.$router.push({ name: "AddressList", query: { type: "orderAdd", shareid: this.shareid, merchid: this.merchid } });
      }
    },
    addMoreAddress() {
      if (this.islogin) {
        this.$router.push({ name: "addMoreAddress", query: { shareid: this.shareid, merchid: this.merchid } });
      }
    },
    // 识别地址信息
    shibieAddress(index) {
      if (this.islogin) {
        console.log(index);
        if (this.address[index].id && parseInt(this.address[index].id) > 0) {
          let address = null;
          address = this.address[index];
          this.Addaddress.area = address.province+ "/" +address.city+"/"+address.area; // 省市县
          this.Addaddress.areaCode = address.areaCode; // 省市县code
          this.Addaddress.street = address.street; // 街道地址 or 识别出来的详细地址
          this.Addaddress.conName = address.name; // 姓名 or 识别出来的姓名
          this.Addaddress.conMobile = address.mobile; // 手机号 or 识别出来的手机号
          this.Addaddress.province = address.province; // 识别出来的省
          this.Addaddress.city = address.city; // 识别出来的市
          this.Addaddress.county = address.area; // 识别出来的区县
          this.Addaddress.index = index; // 识别出来的区县
          this.Addaddress.id = address.id; // 识别出来的区县
        }
        
        this.showshibieAddress = true;
      }else {
        this.$toast("请登录后再添加地址")
      }
    },
    // 确认选择省市县
    onConfirm(val) {
      console.log(val);
      this.showArea = false;
      this.Addaddress.area = "";
      this.Addaddress.areaCode = 0;
      if (val) {
        if (val[0]) {
          this.Addaddress.province = val[0].name;
        }
        if (val[0]) {
          this.Addaddress.city = val[1].name;
        }
        if (val[0]) {
          this.Addaddress.county = val[2].name;
        }
        
        val.forEach((item) => {
          if (item.name && item.code) {
            this.Addaddress.area += item.name + "/";
            this.Addaddress.areaCode = item.code;
          }
        })
      }
      this.Addaddress.area = this.Addaddress.area.substr(0, (this.Addaddress.area.length - 1));

    },
    // 保存识别地址
    onSaveAddress() {
      let _that = null;
      _that = this;
      if (!this.islogin) {
        this.$toast("请登录后再添加收货人");
        return false;
      }

      if (_that.Addaddress.conName) {
        _that.Addaddress.conName = _that.Addaddress.conName.trim();
      }else{
        _that.$toast("请填写收货人姓名");
        return false;
      }
      if (_that.Addaddress.conMobile) {
        _that.Addaddress.conMobile = _that.Addaddress.conMobile.trim();
      }else{
        _that.$toast("请填写手机号");
        return false;
      }
      if (_that.Addaddress.province) {
        _that.Addaddress.province = _that.Addaddress.province.trim();
      }else{
        _that.$toast("请选择省");
        return false;
      }
      if (_that.Addaddress.city) {
        _that.Addaddress.city = _that.Addaddress.city.trim();
      }else{
        _that.$toast("请选择城市");
        return false;
      }
      if (_that.Addaddress.county) {
        _that.Addaddress.county = _that.Addaddress.county.trim();
      }else{
        _that.$toast("请选择区县");
        return false;
      }
      if (_that.Addaddress.street) {
        _that.Addaddress.street = _that.Addaddress.street.trim();
      }else{
        _that.$toast("请填写详细地址");
        return false;
      }
      if (!_that.saveAddBtn) {
        _that.saveAddBtn = true;
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
        _that.$axios
          .post(
            _that.$store.state.domain + "web/address/add",
            _that.$qs.stringify({
              content: {
                name: _that.Addaddress.conName,
                tel: _that.Addaddress.conMobile,
                province: _that.Addaddress.province,
                city: _that.Addaddress.city,
                county: _that.Addaddress.county,
                addressDetail: _that.Addaddress.street,
                areaCode: _that.Addaddress.areaCode,
              },
              id: _that.Addaddress.id ? _that.Addaddress.id : 0
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              let index = -1;
              index = _that.Addaddress.index;
              _that.address[index].name = _that.Addaddress.conName;
              _that.address[index].mobile = _that.Addaddress.conMobile;
              _that.address[index].province = _that.Addaddress.province;
              _that.address[index].city = _that.Addaddress.city;
              _that.address[index].county = _that.Addaddress.county;
              _that.address[index].area = _that.Addaddress.county;
              _that.address[index].street = _that.Addaddress.street;
              _that.address[index].areaCode = _that.Addaddress.areaCode;
              _that.address[index].id = _that.Addaddress.id;

              localStorage.setItem("DK_ADDRESS", JSON.stringify(_that.address));

              _that.Addaddress.area = ""; // 省市县
              _that.Addaddress.areaCode = ""; // 省市县code
              _that.Addaddress.street = ""; // 街道地址 or 识别出来的详细地址
              _that.Addaddress.conName = ""; // 姓名 or 识别出来的姓名
              _that.Addaddress.conMobile = ""; // 手机号 or 识别出来的手机号
              _that.Addaddress.province = ""; // 识别出来的省
              _that.Addaddress.city = ""; // 识别出来的市
              _that.Addaddress.county = ""; // 识别出来的区县
              _that.showshibieAddress = false;
              _that.addressStr = ""; // 需要识别的字符串

              _that.getgoodsinfo();
              _that.saveAddBtn = false;
            }else {
              _that.$toast(res.data.msg ? res.data.msg : "保存失败")
            }
          })
          .catch(err => {
            console.log(err);
          })
      }
        
    },
    // 执行识别地址
    parseAddress() {
      let address = null;
      address = SmartAddress.smart(this.addressStr);

      let name = "";
      if (address.name) {
        name = address.name;
      }else {
        name = address.residueaddress;
      }
      if (address.countyCode) {
        this.Address.areaCode = address.countyCode;
      }else {
        if (address.cityCode) {
          this.Address.areaCode = address.cityCode;
        }else {
          if (address.provinceCode) {
            this.Address.areaCode = address.provinceCode;
          }
        }
      }

      this.Address.conName = name;
      this.Address.conMobile = address.mobile;
      this.Address.province = address.province;
      this.Address.city = address.city;
      this.Address.county = address.county;
      this.Address.street = address.address;
      if (address.province || address.city || address.county) {
        this.Address.area = this.province + "/" + this.city + "/" + this.county;
      }
      console.log(address);
    },
    // 改变产品数量
    numOnChange(num, name) {
      console.log(num, name);
      let index = -1;
      index = this.addressListIndex;
      this.address[index].goods.goodsnum = num;
      this.orderinfo[0].goods_info[0].num = num;
    },

    // 上传前
    beforeRead() {
      if (!this.islogin) {
        this.$toast("未登录")
        return false;
      }else{
        return true;
      }
    },
    // 控价截图上传
    afterRead(file, detail) {
      console.log(file, detail);
      let _that = null;
      _that = this;
      if (!this.islogin) {
        this.$toast("未登录")
        return false;
      }
      const fordata = new FormData();
      fordata.append('file',file.file);
      fordata.append('dir','image/order/kongjia/');
      fordata.append('merchid',_that.merchid);
      _that.$axios.defaults.headers["Content-Type"] =  "multipart/form-data";
      _that.$axios.post(
        "https://api.midbest.cn/admin/image/shangchuan",
        fordata
      ).then( response =>{
        
        if (response.data && response.data.code == 100000) {
          let index = -1;
          index = _that.addressListIndex;

          _that.address[index].goods.kongjiapic = response.data.img_url;
          console.log(_that.address);
          _that.uploadpic[detail.name] = response.data.img_url;
        }else {
          _that.uploadpic[detail.name] = "";
          _that.$toast(response.data.msg ? response.data.msg : "上传失败");
        }
      }).catch( error =>{
        console.log(error);
      })
    },
    // 删除截图
    beforeDelete(file, detail) {
      let index = -1;
      index = this.addressListIndex;
      this.address[index].goods.kongjiapic = "";
      this.uploadpic.splice(detail.name, 1);
    },
    // 添加留言信息
    messageInput(val, index) {
      this.address[this.addressListIndex].goods.message = val;
      this.address[this.addressListIndex].goods.info[index].message = val;
    },
    // 提交订单
    onSubmit() {
      let _that = null;
      _that = this;

      const order = [];
      const goods = [];
      console.log(_that.address, "_that.address");
      if (_that.address && _that.address.length > 0) {
        _that.address.forEach(item => {
          const goods = [];
          if (item.goods && parseInt(item.goods.goodsid) > 0) {
            console.log(item.goods);
            goods.push({
              goodsid: item.goods.goodsid,
              optionid: item.goods.optionid,
              num: item.goods.goodsnum,
              message: item.goods.message,
              kongjiapic: item.goods.kongjiapic
            });
          }else {
            goods.push({
              goodsid: _that.req[0].goodsid,
              optionid: _that.req[0].optionid,
              num: _that.req[0].num,
              message: "",
              kongjiapic: ""
            });
          }
          order.push({
            goods: goods,
            "merchid_fake": _that.merchid,
            "address_ids": item.id
          });
        })
      }else {
        goods.push({
          goodsid: _that.req[0].goodsid,
          optionid: _that.req[0].optionid,
          num: _that.req[0].num,
          message: "",
          kongjiapic: ""
        });
        order.push({
          goods: goods,
          "merchid_fake": _that.merchid,
          "address_ids": 0
        });
      }

      if (_that.noxiadan) {
        _that.$toast({
          message: _that.noxiadanText,
        })
        return false;
      }

      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      const DKUID = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers.common["Authorization"] = DKUID;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/orderv2/add",
          _that.$qs.stringify({
            order: order,
            "merchid_fake": _that.merchid
          })
        )
        .then(response => {
          console.log(response);    
          if (response.data.code == 100000) {
            let uid = 0;
            uid = response.data.data.mid;
            _that.uploadpic = [];
            localStorage.removeItem("DK_ADDRESS");
            localStorage.removeItem("DK_DATA");
            localStorage.setItem("ordersn", response.data.data.ordersn);
            localStorage.setItem("money", response.data.data.money);
            localStorage.setItem("orderid", response.data.data.oid);
            _that.address = {};
            _that.$router.replace({name: "CheckPayment", query: {shareid: _that.shareid, merchid: _that.merchid}})
          }else {
            _that.loadingbtn = true; // 提交等待中
            _that.$toast({
              message:response.data.msg
            });
          }
        })
        .catch(error => {
          console.log(error);
          _that.loadingbtn = true; // 提交等待中
        });
    },
    // 登陆or注册
    onLogin() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.post(
        _that.$store.state.domain + "web/user/login",
        _that.$qs.stringify({
          tel: _that.mobile,
          code: _that.code,
          codetype: 2,
          wxinfo: localStorage.getItem("DK_WXINFO"),
          shareid: _that.shareid,
          openid: localStorage.getItem("DK_OPENID"),
          memberid: localStorage.getItem("DK_MEMBERID")
        })
      ).then(function(response){
        if (response.data && response.data.code == 100000) {
          localStorage.setItem("DK_UID",response.data.data)
          localStorage.setItem("DK_ISLOGIN",true)
          localStorage.setItem("DK_MEMBERID", response.data.user.id)
          localStorage.setItem("DK_STRID",response.data.user.strId);
          _that.strId = response.data.user.strId;
          _that.$notify({
            type:'success',
            message: response.data.msg?response.data.msg:'登陆成功'
          })
          _that.islogin = localStorage.getItem("DK_ISLOGIN") ? true : false;
          localStorage.removeItem("DK_WXINFO");
          _that.openidBindUid(); // 绑定用户id
        }else{
          _that.$notify({
            type:'warning',
            message: response.data && response.data.msg?response.data.msg:'登陆失败'
          })
        }
      }).catch(function(error){
        console.log(error);
      })
    },
    // 倒计时完成后执行
    finish() {
      this.btnDisabled = false;
      this.showTxt = true;
    },
    // 发送验证码
    forgetpwd() {
      if (this.mobile && this.validator(this.mobile)) {
        this.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        this.$axios.post(
          this.$store.state.domain + "web/user/send_sms",
          this.$qs.stringify({
            tel: this.mobile,
            type: 'login'
          })
        )
        this.$refs.daojishi.reset();
        this.time = 60 * 1000;
        this.btnDisabled = true;
        this.showTxt = false;
      }else{
        this.$refs.mobile.focus();
      }
    },
    // openid 和用户id绑定 
    openidBindUid() {
      let _that = null;
      _that = this;
      const uid = localStorage.getItem("DK_MEMBERID");
      const openid = localStorage.getItem("DK_OPENID");
      if (parseInt(uid) > 0 && openid) {
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/pay/setopenid",
            _that.$qs.stringify({
              mid: parseInt(uid),
              openid: openid,
              type: "daka"
            })
          )
          .then(res => {
            console.log(res);
          })
          .catch(err => {
            console.log(err);
          })
      }
    },
    // 查询上级信息
    getAgentInfo() {
      let _that = null;
      _that = this;
      if (parseInt(_that.shareid) > 0) {
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/getparentByMid",
            _that.$qs.stringify({
              id: _that.shareid
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              _that.agentInfo = res.data.data;
              _that.agentCode = res.data.data.agent_code ? res.data.data.agent_code : "";
            }
          })
          .catch(error => {
            console.log(error);
          })
      }
    },
    // 进入详情页
    details(id) {
      console.log(id);
      this.$router.push({name: "Details", query: {id: id, shareid: this.shareid, merchid: this.merchid, _t: 3 } });
    },

    /**
     * 查看导入模板
     */
    showExcel() {
      ImagePreview({
        images: ['https://img.midbest.cn/uploads/image/addAddress/shili.png'],
        closeable: true,
      });
    },

    /**
     * 进入模板下载页面
     */
    downloadExcel() {
      console.log("downloadExcel");
      this.$router.push({name: "Download", query: { shareid: this.shareid, merchid: this.merchid } })
    },
    /**
     * 上传导入地址页面
     */
    uploadExcel(file) {
      let _that = null;
      _that = this;
      console.log(file, "uploadExcel");
      const fordata = new FormData();
      fordata.append('file',file.file);
      fordata.append('dir','image/excel/');
      fordata.append('merchid',_that.merchid);
      _that.$axios.defaults.headers["Content-Type"] =  "multipart/form-data";
      _that.$axios
        .post(
          _that.$store.state.domain + "admin/image/shangchuan",
          fordata
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.getexcel(res.data.url);
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    /**
     * 获取导入地址
     */
    getexcel(url) {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/orderv2/read_address_list",
          _that.$qs.stringify({
            "excel_url": "https://img.midbest.cn/uploads/image/excel/2021/04/10/d03ba4ce21c309d463f4008cb1204c45.xlsx"
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            localStorage.setItem("uploadsList", JSON.stringify(res.data.data));
            _that.addMoreAddress();
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    /**
     * 选择商品
     */
    chooseGoods(index) {
      let _that = null;
      _that = this;
      _that.addressListIndex = index;
      _that.orderinfo = [];
      console.log(_that.address);
      _that.orderinfo = _that.address[index].goods.info;

      if (_that.address[index].goods.message) {
        _that.orderinfo[0].message = _that.address[index].goods.message;
      }
      _that.showChoseGoods = true;
      _that.showOrderinfo = true;
    },

    /**
     * 确认按钮
     */
    orderinfoConfirm() {
      console.log("orderinfoConfirm");
      localStorage.setItem("DK_ADDRESS", JSON.stringify(this.address));
      this.getgoodsinfo();
    },
    /**
     * 取消按钮
     */
    orderinfoCancel() {
      console.log("orderinfoCancel");
      this.showChoseGoods = false;
      let index = -1;
      index =this.addressListIndex;
      this.orderinfo[0].goods_info[0].num = this.orderinfo[0].goodsNum;
      this.address[index].goods.goodsnum = this.orderinfo[0].goodsNum;
    },

    /**
     * 输入框内容发生变化时
     */
    keywordsChange(e) {
      console.log(e);
      this.keywords = e;
      if (this.keywords.trim().length > 0) {
        this.showOrderinfo = false;
        this.showgoodslist = true;
      }else{
        this.showgoodslist = false;
        this.showOrderinfo = true;
      }
    },
    /**
     * 搜索
     */
    kwdonSearch() {
      console.log("kwdonSearch");
      let _that = null;
      _that = this;
      this.showOrderinfo = false;
      this.showgoodslist = true;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/goods/get_search",
          _that.$qs.stringify({
            size: _that.size,
            offset: _that.offset,
            keywords: _that.keywords,
            merchid: _that.merchid
          })
        )
        .then(res => {
          console.log(res);
          _that.finished = false;
          _that.nodata = false;
          if(res.data.length > 0) {
            if (_that.offset == 0) {
              _that.goodsList = res.data;
            }else{
              res.data.forEach(item=> {
                _that.goodsList.push(item);
              })
            }
          }else{
            _that.finished = true;
            if (_that.offset == 0) {
              _that.nodata = true;
            }
          }
          _that.offset += res.data.length;
          _that.showGoods = false;
          _that.showgoodslist = true;
          _that.showOrderinfo = false;
        })
        .catch(err => {
          console.log(err);
        })
    },

    /**
     * 选择商品
     */
    checkgoods(id) {
      this.$toast({
        message: '加载中...',
        type: 'loading',
        forbidClick: true,
        duration: 0
      })
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/goods/get_info",
          _that.$qs.stringify({
            id: id,
            merchid: _that.merchid,
          })
        )
        .then(function (response) {
          _that.$toast.clear();
          if (response && response.data) {
            // 产品信息
            if (response.data.info) {
              _that.info = response.data.info;
              _that.skuData.goods.title = _that.info.title;
              _that.skuData.goodsid = _that.info.id;
              _that.skuData.goods.picture = _that.info.thumb;
              _that.skuData.sku.price = (_that.info.show_price * 1).toFixed(2);
              _that.buybtnable = false;
              _that.content = _that.info.content ? _that.info.content : '';
            } else {
              _that.$toast(response.data.msg ? response.data.msg : "该产品已下架或不存在");
              return false;
            }
            // 规格信息
            if (response.data.spec) {
              const spec = [];
              response.data.spec.forEach((item, index) => {
                if (item.title) {
                  _that.skuTitle += item.title + "、";
                }
                const arr = {
                  k: item.title,
                  "k_id": item.id,
                  v: item.children,
                  "k_s": "s" + (index + 1),
                  largeImageMode: false,
                };
                spec.push(arr);
              });
              _that.skuData.sku.tree = spec;
              _that.skuTitle = _that.skuTitle.substring(0, _that.skuTitle.length - 1);
              _that.skuData.initialSku = {
                s1: response.data.spec[0].children[0].id,
                s2: response.data.spec[1] && response.data.spec[1].children[0].id
                    ? response.data.spec[1].children[0].id
                    : "",
                s3: response.data.spec[2] && response.data.spec[2].children[0].id
                    ? response.data.spec[2].children[0].id
                    : "",
                selectedNum: 1,
                yushouTime: "",
              };
            }
            // sku信息
            if (response.data.option) {
              const option = [];
              response.data.option.forEach((item) => {
                let specid = [];
                if (item.specs) {
                  specid = item.specs.split("_");
                }
                const arr = {
                  id: item.id,
                  price: item.price * 100, //价格
                  s1: specid[0],
                  s2: specid[1],
                  s3: specid[2],
                  "stock_num": parseInt(item.stock), //库存
                  "yushou_time": item.yushou_time,
                };
                option.push(arr);
              });
              _that.skuData.sku.list = option;
              _that.skuData.sku["collection_id"] = option[0].id;
            }
            _that.showBase = true;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 选择SKU的方法
    skucheck(res) {
      console.log(res, this.$refs.vanskudata);
      if (res.selectedSkuComb && res.selectedSkuComb.yushou_time) {
        // this.buybtntext = "预售购买";
        this.yushouTime = res.selectedSkuComb.yushou_time;
      } else {
        // this.buybtntext = "立即购买";
        this.yushouTime = "";
      }
    },
    /* 未选择规格提示 */
    customSkuValidator() {
      return "请选择" + this.skuTitle;
    },
    /* 执行立即购买按钮 */
    onBuyClicked(data) {
      let _that = null;
      _that = this;
      
      if (!_that.info.id || _that.info.id <= 0) {
        _that.$toast("该产品已下架或不存在");
        return false;
      }
      let index = -1
      index = this.addressListIndex;
      this.address[index].goods = {
        goodsid: data.goodsId,
        goodsnum: data.selectedNum,
        optionid: data.selectedSkuComb.id,
        kongjiapic: '',
        message: ''
      }
      
      this.getoneOrder();
    },
    /**
     * 获取单独的订单信息
     */
    getoneOrder() {
      let that = null;
      that = this;
      const order = [];
      const goods = [];
      const index = this.addressListIndex;
      this.$toast({
        message: '加载中...',
        type: 'loading',
        forbidClick: true,
        duration: 0
      })
      goods.push({
        goodsid: this.address[index].goods.goodsid,
        optionid: this.address[index].goods.optionid,
        num: this.address[index].goods.goodsnum,
      });
      order.push({
        goods: goods,
        "merchid_fake": that.merchid,
        "address_ids": this.address[index].id
      });
      that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "web/orderv2/confirm",
          that.$qs.stringify({
            order: order
          })
        )
        .then(res => {
          console.log(res);
          that.$toast.clear();
          if (res.data.code == 100000) {
            that.address[index].goods.info = res.data.data['goods_info'][0].goods;
            that.orderinfo = res.data.data['goods_info'][0].goods;
            that.showBase = false;
            that.showOrderinfo = true;
            that.showgoodslist = false;
            localStorage.setItem("DK_ADDRESS", JSON.stringify(that.address));
          }else{
            that.$toast(res.data.msg ? res.data.msg : '错误');
          }
        })
        .catch(err => {
          console.log(err);
        })
      
    },
  }
};
</script>
<style lang="less">
.v1paymentmore {
  background-color: #ededed;
  position: relative;
  z-index: 2;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  .van-nav-bar {
    .van-icon {
      color: #333;
    }
    .van-nav-bar__text{
      color: #333;
    }
  }
  // 收货地址信息
  .postage_address {
    position: relative;
    background-color: #fff;
    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 2px;
      background: -webkit-repeating-linear-gradient(
        135deg,
        #ff6c6c 0,
        #ff6c6c 20%,
        transparent 0,
        transparent 25%,
        #1989fa 0,
        #1989fa 45%,
        transparent 0,
        transparent 50%
      );
      background: repeating-linear-gradient(
        -45deg,
        #ff6c6c 0,
        #ff6c6c 20%,
        transparent 0,
        transparent 25%,
        #1989fa 0,
        #1989fa 45%,
        transparent 0,
        transparent 50%
      );
      background-size: 80px;
      content: "";
    }
    
    .van-cell {
      padding: 10px 16px;
      .van-cell__title {
        flex: 1;
        text-align: left;
      }
      .address_icon {
        font-size: 18px;
        padding-right: 16px;
      }
      .add {
        font-size: 40px;
        color: #1989fa;
      }
      .check_address {
        font-size: 18px;
        height: 40px;
        line-height: 40px;
        width: 40px;
      }
      .name {
        font-weight: bold;
      }
      .area {
        line-height: 20px;
        font-size: 12px;
        color: #666;
      }
    }
    .address_list {
      padding-bottom: 10px;
      .van-cell {
        padding: 10px 16px 0;
      }
    }
    // 虚拟产品手机号
    .van-field {
      padding: 16px 20px;
      line-height: 30px;
      font-size: 16px;
      .van-field__label {
        max-width: 70px;
      }
      .van-field__value {
        flex: 1;
      }
    }
  }
  // 产品列表
  .goods_main {
    padding: 10px 0 0;
    .goods_list_detail{
      border-radius: 10px;
      background-color: #fff;
      margin-bottom: 10px;
      overflow: hidden;
      .van-card {
        border-radius: 10px;
        overflow: hidden;
        text-align: left;
        background-color: #fff;
        margin-top: 0px;
        .van-card__thumb {
          overflow: hidden;
        }
        .van-card__price {
          color: #f22f31;
        }
        .van-card__footer {
          margin-top: 10px;
          .van-cell {
            padding: 5px 0;
            line-height: 30px;
            .van-field__label {
              width: 35px;
            }
            .van-cell__value {
              flex: 1;
            }
          }
        }
      }
      .goods_set {
        padding-top: 10px;
        .van-cell {
          .van-cell__title {
            width: 60px;
            text-align: left;
          }
          .van-cell__value {
            flex: 1;
            text-align: right;
          }
        }
        // 控价传图
        .kongjia_tupian {
          overflow: hidden;
          padding: 10px 16px;
          background-color: #fff;
          border-radius: 10px;
          .van-cell {
            padding: 0;
            .van-cell__title {
              flex: 1;
              color: #333;
            }
            .van-cell__value {
              flex: none;
              .van-uploader__upload {
                width: 60px;
                height: 60px;
                margin: 0;
              }
              .van-uploader__preview{
                margin: 0;
              }
              .van-uploader__preview-image {
                width: 60px;
                height: 60px;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
  
  // 订单总计
  .order_total {
    padding-bottom: 60px;
    .van-cell-group {
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;
      .van-cell {
        font-weight: bold;
        .van-cell__value {
          flex: 1;
          text-align: right;
          color: #353535;
        }
      }
    }
  }
  // 未登录输入手机号密码
  .logout {
    background-color: #fff;
    overflow: hidden;
    margin-bottom: 10px;
    padding: 20px 0;
    .login_tishi {
      font-size: 14px;
      font-size: #333;
      margin-bottom: 10px;
    }
    .van-button {
      height: 33px;
    }
    .van-field__label {
      width: 50px;
    }
    .van-cell__value{
      flex: 1;
    }
    .van-count-down{
      background-color: #bbb;
      padding: 1px 6px;
      .seconds{
        color: #ffffff;
        font-size: 12px;
      }
    }
    .agent_title {
      width: 60px;
      margin-right: 12px;
      text-align: left;
    }
    .agent_value {
      text-align: left;
    }
  }
  .demo_cont {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;

    .demo-animate-block {
      position: absolute;
      top: 10%;
      left: 10%;
      width: 80%;
      background-color: #fff;
      border-radius: 3px;
      padding: 10px;
      margin: -10px 0 0 -10px;
      .van-cell__title{
        width: 60px;
      }
      .van-cell__value{
        flex: 1;
      }
      .address_shibie {
        margin-top: 10px;
        overflow: hidden;
        background-color: #fff;
        padding: 10px 0;
        text-align: right;
        .van-button{
          margin-top: 10px;
          margin-right: 16px;
          padding: 0 20px;
        }
        .van-button--hairline::after{
          border-radius: 10px;
        }
      }
    }
  }
  // 选择商品弹框
  .search {
    .van-search {
      .van-cell__value {
        flex: 1;
      }
    }
    .goodslist_main {
      overflow-x: hidden;
      overflow-y: auto;
      .van-cell {
        .van-cell__title {
          flex: 1;
          text-align: left;
        }
      }
      .van-sku-header__goods-info {
        text-align: left;
      }
      .van-sku-body {
        text-align: left;
      }
    }
  }
  .red {
    color: #e2280a;
  }
}
</style>
